<script setup lang="ts">
import type { DeepdiveMarkdown } from '../lib/deepdives';
import { useDeepdivesStore } from '../stores/deepdiveStore';

const ds = useDeepdivesStore()
const deepdive = ds.active as DeepdiveMarkdown

const parsedMarkdown = await fetch(deepdive.partialPath).then(res => res.text())

</script>

<template>
  <div 
    class="prose w-full prose-overrides max-w-none"
    v-if="ds.active && ds.active.type === 'markdown'"
    v-html="parsedMarkdown"
  >

  </div>
</template>