<script setup lang="ts">
import { useDeepdivesStore } from "./stores/deepdiveStore";
import { onKeyStroke } from "@vueuse/core";
import { UseFocusTrap } from '@vueuse/integrations/useFocusTrap/component'
import DeepdiveComponent from "./renderers/DeepdiveComponent.vue";
import DeepdiveMarkdown from "./renderers/DeepdiveMarkdown.vue";

const deepdivesStore = useDeepdivesStore()

onKeyStroke('Escape', deepdivesStore.close)

</script>

<template>
  <!-- TODO: document z index usage -->
  <div 
    class="fixed isolate z-[10000] left-0 top-0 h-[100dvh] w-full pointer-events-none flex items-center justify-center" 
  >
    <!-- backdrop -->
    <Transition
      enter-active-class="duration-500 ease-in-out"
      leave-active-class="duration-500 ease-in-out"
      enter-from-class="opacity-0 backdrop-blur-0"
      leave-to-class="opacity-0 backdrop-blur-0"   
    >
      <div 
        v-if="deepdivesStore.active"
        class="absolute inset-0 bg-brand-stroke/10 backdrop-blur-12" 
      />
    </Transition>
    <!-- modal -->
    <Transition
      enter-active-class="duration-300 ease-in-out"
      leave-active-class="duration-300 ease-in-out"
      enter-from-class="translate-y-12 opacity-0"
      leave-to-class="translate-y-full"
    >
      <UseFocusTrap 
        as="div"
        v-if="deepdivesStore.active"
        :key="deepdivesStore.active.path"
        aria-modal="true"
        role="dialog"
        tabindex="-1"
        class="absolute inset-0 overflow-y-scroll overflow-x-hidden pointer-events-auto outline-none border-none" 
        @click="deepdivesStore.close"
      >
        <div
          class="container isolate flex justify-center"
        >
          <div 
            @click.stop
            class="min-h-[80vh] p-6 xs:p-8 sm:p-12 lt-lg:w-full lg:w-220 bg-brand-background rounded-6 mt-[15vh] mb-12 border-brand-stroke/10 border-solid border-2" 
          >
            <Transition
              enter-active-class="duration-300 ease-in-out"
              enter-from-class="opacity-0 translate-y-2"
              mode="out-in"
            >
              <KeepAlive>
                <Suspense>

                  <template #fallback>
                    <div class="h-full w-full grid place-content-center" >
                      <div class="flex items-center flex-col" >
                        <span class="h-8 w-8 bg-brand-gradient i-tdesign:loading inline-block animate-spin animate-duration-1400" />
                        <span class="mt-6 text-xl" >
                          Downloading content...
                        </span>
                      </div>
                      
                    </div>
                  </template>

                  <template #default >
                    <DeepdiveComponent v-if="deepdivesStore.active.type === 'component'" />
                    <DeepdiveMarkdown v-else-if="deepdivesStore.active.type === 'markdown'" />
                  </template>

                </Suspense>   
              </KeepAlive>
            </Transition>
          </div>
        </div>
        <!-- close button -->
        <Transition
          appear-active-class="duration-300 ease-in-out"
          appear-from-class="scale-0 opacity-0"
          appear
        >
          <button 
            :class="[
              'fixed bottom-0 left-1/2 bottom-4 -translate-x-1/2 h-16 w-16 bg-brand-background border-brand-stroke/10 border-solid border-2 flex items-center justify-center rounded-full',
              'hover:(border-brand-primary) duration-300 ease-in-out focus-visible:(outline-none) focus:border-brand-primary' 
            ]"
            @click.stop="deepdivesStore.close"
          >
            <span class="i-material-symbols:close h-8 w-8 inline-block text-white" />
            <span class="sr-only" >close deepdive</span>
          </button>
        </Transition>
      </UseFocusTrap>
    </Transition>
  </div>
</template>