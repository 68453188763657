<script setup lang="ts">
import { defineAsyncComponent } from 'vue';
import { useDeepdivesStore } from '../stores/deepdiveStore';

const ds = useDeepdivesStore()

</script>

<template>
  <component 
    v-if="ds.active && ds.active.type === 'component'"
    :is="defineAsyncComponent(ds.active.component)" 
  />
</template>